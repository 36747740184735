import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { H1 } from "../components/Headline"

import img from "../images/ulf_main.jpg"
import logo from "../images/logo@2x.png"

import "./index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Hem" />
    <div className="Index">
      <div className="Index-flex1">
        <img src={img} className="Index-img" alt="Portrait of Ulf" />
        <h6>ULF LINDEGÅRD</h6>
      </div>
      <div className="Index-flex2">
        <div className="Index-display">
          <H1>UL JURIDIK</H1>
          <p>
            Rådgivning och operativt stöd inom:
            <ul>
              <li>Kommersiell hyresjuridik och lokalförsörjning</li>
              <li>Upphovsrätt och musikjuridik</li>
            </ul>
            Välkommen till UL JURIDIK
          </p>
        </div>
        <div className="Index-quote">
          <span>
            <p>Here, there and everywhere</p>
            <p>Lennon/McCartney</p>
          </span>
          <img src={logo} alt="Logo" />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
