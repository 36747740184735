/**
 * Headline.js
 * h1, h3,
 */
import React from "react";
import "./Headline.css";

const H1 = props => {
  const { className } = props;
  return (
    <h1 className={"Headline Headline-h1 " + className}>{props.children}</h1>
  );
};
H1.defaultProps = {
  className: ""
};

const H3 = props => {
  const { className } = props;
  return (
    <h3 className={"Headline Headline-h3 " + className}>{props.children}</h3>
  );
};
H3.defaultProps = {
  className: ""
};

export { H1, H3 };